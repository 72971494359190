import React from 'react';
import './DeliciousRecipes.css';
import { Stack } from '@mui/material';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { images } from '../../constants';
import { Link } from 'react-router-dom';


const DeliciousRecipes = () => {
  return (
    <section className='container delicious-recipes-section'>
        <Stack className='delicious-recipes-box' maxWidth='100%' sx={{ justifyContent: 'end', alignItems: 'center', flexWrap: 'wrap', gap: { md: 5 } }} direction={{ md: 'row' }} >

            <Stack className='delicious-recipes-content' sx={{ justifyContent: 'center', alignItems: 'center' }} data-aos="fade-right" data-aos-delay="400">
                <Stack sx={{ justifyContent: 'center', alignItems: 'center', px: { sm: '60px', md: '60px' }, py: { sm: '60px' } }}>
                    <div className='section-heading'>
                        <h2 className='subtitle'>
                            Best & Good
                        </h2>
                        <h1 className='title'>
                        Yummy recipes
                        </h1>
                    </div>

                    <p className='delicious-recipes-text' style={{ margin: '6px 0 18px 0'}}>
                        At Roadhouse Wings, our carefully chosen recipes combine time-tested favourites with fresh twists to provide a pleasant dining treat. 
                    </p>

                    {/* <Link to='/about-us' style={{textDecoration: 'none'}}>
                        <div className='delicious-recipes-btn'>
                            <p>View More</p>
                        </div>
                    </Link> */}
                </Stack>
            </Stack>

            <Stack sx={{ justifyContent: 'center', alignItems: 'center', gap: 4 }} direction={{ md: 'row' }}>
                <div className='delicious-recipes-img' >
                    <LazyLoadImage 
                        effect="blur"
                        className="img"
                        src={images.DeliciousRecipes1} 
                        alt='Delicious Recipes' 
                    />
                </div>
                <div className='delicious-recipes-img' >
                    <LazyLoadImage 
                        effect="blur"
                        className="img"
                        src={images.DeliciousRecipes2} 
                        alt='Delicious Recipes' 
                    />
                </div>
            </Stack>

            <div className='backgroung-img1' data-aos="zoom-in-up">
                <img src={images.bg6} alt='backgroung img' />
            </div>

            <div className='backgroung-img2' data-aos="zoom-in-down">
                <img src={images.bg11} alt='backgroung img' />
            </div>
        </Stack>
    </section>
  )
}

export default DeliciousRecipes