import React from "react";
import "./AboutUs.css";
import { Box, Grid, Stack } from "@mui/material";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { images } from '../../constants';
import FromUs from "./FromUs";
import { Link } from "react-router-dom";


const AboutUs = () => {
    return (
        <section className="container about-us-section">

            <div className='section-heading '>
                <h2 className='subtitle' data-aos="fade-up" data-aos-delay="200">
                    About Us
                </h2>
                <h1 className='title' data-aos="fade-up" data-aos-delay="250">
                    Our Story
                </h1>
            </div>

            <div className='backgroung-img1' data-aos="fade-left" data-aos-delay="400">
                <LazyLoadImage 
                    effect="blur"
                    className="img"
                    src={images.bg4} 
                    alt='backgroung img' 
                />
            </div>

            <div className='backgroung-img2' data-aos="zoom-out-up-right" data-aos-delay="400">
                <LazyLoadImage 
                    effect="blur"
                    className="img"
                    src={images.bg12} 
                    alt='backgroung img' 
                />
            </div>

            <Stack maxWidth='100%' sx={{ pt: { md: '52px' }}}>
                <Grid container sx={{ alignItems: 'center' }}>
                    <Grid className='aboutus-img-grid' item xs={12} sm={12} md={5} lg={5}>
                        <div className='aboutus-img'>
                            <LazyLoadImage 
                                effect="blur"
                                className="img"
                                src={images.aboutImg} 
                                alt='About img' 
                            />
                        </div>
                    </Grid>
                    <Grid className='aboutus-content-grid' item xs={12} sm={12} md={7} lg={7}>
                        <Stack>
                            <p className="aboutus-text" data-aos="fade-up" data-aos-delay="400">
                                Welcome to Roadhouse Wings – Your Ultimate Wing Destination in Toronto! 
                                Our culinary haven goes beyond exceptional cuisine, weaving a symphony of taste, ambiance, and passion. 
                                As dedicated artisans, we take immense pride in crafting not only the finest dishes but also curating an 
                                extraordinary selection of liquor items to elevate your dining experience to new heights.
                                <br/><br/>
                                Experience the journey with Roadhouse Wings – a celebration of taste, passion, and culinary excellence.
                            </p>
                            <Stack className="aboutus-cta-box" sx={{ justifyContent: 'start', alignItems: 'center', flexWrap: 'wrap', gap: { xs: 2, md: 4 }, pt: { xs: '46px', sm: '48px', md: '54px', lg: '56px' } }} direction='row' >
                                <Link to='/menu' style={{textDecoration: 'none'}}>
                                    <div className='aboutus-cta-btn cta-menu' data-aos="fade-up">
                                        Our Menu
                                    </div>
                                </Link>
                                <a href='https://goo.gl/maps/3fAnnr3wH5oRfQ3D7' target="_blank" style={{textDecoration: 'none'}}>
                                    <div className='aboutus-cta-btn cta-cantact' data-aos="fade-up">
                                        Get Location
                                    </div>
                                </a>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>

            <div className='backgroung-img3' data-aos="zoom-in-down">
                <LazyLoadImage 
                    effect="blur"
                    className="img"
                    src={images.bg9} 
                    alt='backgroung img' 
                />
            </div>

            <FromUs />

        </section>
    );
};

export default AboutUs;

