import React, { useState, useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import './MenuCategories.css';
import Skeleton from '@mui/material/Skeleton';
import menuDetails from './MenuData';
import MenuList from './MenuList';
import { images } from '../../constants';
import menuAPI from '../../api/menu';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const MenuCategories = () => {
    const [menuData, setMenuData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState('');

    async function getData() {
        try {
            const allMenuData = await menuAPI.getAllMenu();
            setMenuData(allMenuData.data);
            setIsLoading(false);
            console.log(allMenuData);
        } catch (error) {
            console.error('Error fetching menu data:', error);
            setMenuData([]); 
        }
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        setSelectedCategory(menuData[0]?.category);
    }, [menuData]);

    // Extract the unique categories and eliminates the duplicates from the array
    const uniqueCategories = [...new Set(menuData.map(item => item.category))];

    return (
        <section className='container menu-categories-section'>
            <Stack maxWidth='100%'>
                <div className='section-heading'>
                    <h2 className='subtitle' data-aos="fade-up" data-aos-delay="200">
                        Our Special
                    </h2>
                    <h1 className='title' data-aos="fade-up" data-aos-delay="250">
                        Menu Categories
                    </h1>
                    <p data-aos="fade-up" data-aos-delay="300">
                        Embark on a tantalizing journey through our diverse selection of savory wing creations.
                    </p>
                </div>

                <div className='backgroung-img1' data-aos="fade-left" data-aos-delay="400">
                    <img src={images.bg4} alt='backgroung img' />
                </div>

                {uniqueCategories &&
                    <Box data-aos="fade-up" data-aos-delay="400">
                        <Stack
                            className='menu-categories-list'
                            direction='row'
                            sx={{
                                overflowX: 'auto',
                                width: { sx: 'auto', md: '100%' },
                                flexDirection: 'row',
                                gap: 6
                            }}
                        >
                            {isLoading ? (
                                // Show Skeletons while data is loading
                                Array.from({ length: 5 }).map((_, idx) => (
                                    <div key={idx}>
                                        <Skeleton variant="text" width={100} />
                                    </div>
                                ))
                            ) : (
                                // Render the actual category buttons
                                uniqueCategories.map((category) => (
                                    <div
                                        key={category}
                                        className={category === selectedCategory ? 'menu-selectedCategories-btn' : 'menu-categories-btn'}
                                        onClick={() => setSelectedCategory(category)}
                                    >
                                        <p>{category}</p>
                                    </div>
                                ))
                            )}
                        </Stack>
                        <hr />
                    </Box>
                }

                <div className='backgroung-img2' data-aos="zoom-in-up" data-aos-delay="500">
                    <img src={images.bg12} alt='backgroung img' />
                </div>

                {menuData.length !== 0 ? (
                    <Stack 
                        sx={{ justifyContent: 'start', alignItems: 'start', flexWrap: 'wrap', gap: { xs: 2, sm: 2, md: 4 } }} 
                        direction={{ xs: 'column', sm: 'row', md: 'row' }}
                        data-aos="fade-up" 
                        data-aos-delay="500"
                    >
                        {isLoading ? (
                            // Show Skeletons while data is loading
                            Array.from({ length: 5 }).map((_, idx) => (
                                <Box key={idx} sx={{ width: { xs: '100%', sm: '48%', md: '31%' }, pt: { xs: 2, sm: 2, md: 4 } }}>
                                    <Skeleton variant="rectangular" sx={{ borderRadius: '8px', height: { xs: 250, sm: 250, md: 300, lg: 300 } }} />
                                    {/* <Skeleton variant="text" width="60%" /> */}
                                </Box>
                            ))
                        ) : (
                            // Render the actual MenuList component with data
                            <MenuList menuData={menuData} selectedCategory={selectedCategory} />
                        )}
                    </Stack>
                ) : (
                    <Stack
                        sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: -2 }}
                    >
                        <LazyLoadImage 
                            effect="blur"
                            style={{
                                width: '250px',
                            }}
                            src={images.Empty} 
                            alt='empty' 
                        />
                        <Typography
                            variant="subtitle1"
                            sx={{ color: 'var(--gray-color)', textAlign: 'center', opacity: 0.6}}
                        >
                            Menu items are not available!
                        </Typography>
                    </Stack>
                )}
            </Stack>
        </section>
    );
}

export default MenuCategories;
