import React from 'react';
import './ContactUs.css';

import { NavBarOther, DeliveryCard, Footer, GoogleMap } from '../../components';
import { Box, Container, Stack, Typography } from '@mui/material';
import { images } from '../../constants';


const index = () => (
  <>
    <NavBarOther />

    <Stack className='map-container'>
      <div className='section-heading'>
        <h2 className='subtitle' data-aos="fade-up" data-aos-delay="200">
          Our Contact
        </h2>
        <h1 className='title' data-aos="fade-up" data-aos-delay="250">
          Connect with us
        </h1>
      </div>

      <div className='backgroung-img1' data-aos="fade-left" data-aos-delay="400">
        <img src={images.bg4} alt='backgroung img' />
      </div>
      
      <div className='contact-google-map' data-aos="fade-up" data-aos-delay="500">
        <GoogleMap/>
      </div>

      <div className='backgroung-img2' data-aos="zoom-in-down">
        <img src={images.bg5} alt='backgroung img' />
      </div>

      <Stack sx={{ justifyContent: 'center', mt: { xs: '-40px', md: '-60px' } }} direction={{ xs: 'column', md: 'row' }}>
        <Box sx={{ width: { xs: '100%', md: '25%' } }} data-aos="fade-up" data-aos-delay="150">
          <div className='contact-info-box'>
            <h3 className='content-title'>Contact info</h3>
            <div className='content-info'>
              <p>2181 Lawrence Ave E, Toronto, ON M1P 2P5</p>
              <a href='tel:+16474580939'>
                <p>+1 (647) 458-0939</p>
              </a>
            </div>
          </div>
        </Box>

        <Box sx={{ width: { xs: '100%', md: '50%' } }} data-aos="fade-up" data-aos-delay="250">
          <div className='opening-hours-box'>
            <h3 className='content-title'>Opening Hours</h3>
            <div className='content-info'>
              <div className='hours'>
                <p><strong>Monday - Friday</strong>
                <br/>8:00am to 9:00pm</p>
              </div>
              <div className='hours'>
                <p><strong>Saturday</strong>
                <br/>8:00am to 9:00pm</p>
              </div>
              <div className='hours'>
                <p><strong>Sunday</strong>
                <br/>CLOSED</p>
              </div>
            </div>
          </div>
        </Box>
      </Stack>
    </Stack>

    <div className='contact-backgroung-img3'>
      <img src={images.bg6} alt='backgroung img' />
    </div>

    <section maxWidth={{ md: '100%'}}>
      <Stack className='delivery-container'>
        <div className='section-heading'>
          <h2 className='subtitle' data-aos="fade-up">
            Online Delivery
          </h2>
          <h1 className='title' data-aos="fade-up">
            Let’s place your orders
          </h1>
        </div>

        <DeliveryCard />
        
      </Stack>
    </section>

    <div className='contact-backgroung-img4' data-aos="zoom-in-right">
      <img src={images.bg7} alt='backgroung img' />
    </div>

    <Footer backgroundColor='rgb(107, 116, 131, 0.05)' />
  </>

)

export default index