import api from "./httpConfig";

const MenuAPI = {

  getAllMenu : async () =>{
    try
    {
    const response = await api.get("/menu/getAllMenu");
    console.log(response.data);
    return (response);
    }   
    catch(err)
    {
      console.log(err);
    }
  },

  postMenu : async (formData) =>{
    try
    {   
    const response = await api.post("/menu/addMenu",formData, {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("jwtToken"),
      }
    });
    return response;
    } 
    catch(err)
    {
      console.log(err);
    }
  }, 

  updateMenu : async (id,formData) =>{
    try
    {   
    const response =  await api.put(`/menu/updateMenu/${id}`,formData, {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("jwtToken"),
      }
    });
    return response;
    } 
    catch(err)
    {
      console.log(err);
    }
  }, 

  deleteMenu : async (id) =>{
    try
    {   
    const response =  await api.delete(`/menu/deleteMenu/${id}`, {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("jwtToken"),
      }
    });
    return response;
    } 
    catch(err)
    {
      console.log(err);
    }
  }, 

  }

  export default MenuAPI;

