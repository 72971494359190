import React from "react";
import "./AboutUs.css";
import { Box, Grid, Stack } from "@mui/material";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { images } from '../../constants';
import { Link } from "react-router-dom";

const FromUs = () => {
  return (
    <section className='from-us-section'>
        <div className='section-heading'>
            <h2 className='subtitle' data-aos="fade-up">
                From Us
            </h2>
            <h1 className='title' data-aos="fade-up">
                You can enjoy
            </h1>
        </div>

        <Stack maxWidth='100%' >
            <Grid container sx={{ alignItems: 'center', gap: { xs: 6, sm: 8, md: 0, lg: 0} }} spacing={{ md: 10 }}>
                <Grid item xs={12} sm={12} md={6} lg={6} data-aos="fade-up" data-aos-delay="100">
                    <div class="fromus-content">
                        <div class="fromus-content-overlay"></div>
                        <LazyLoadImage 
                            effect="blur"
                            className="fromus-content-image"
                            src={images.food} 
                            alt='Food Img' 
                        />
                        <div class="fromus-content-details fadeIn-top">
                            <h1>Foods</h1>
                            <Link to="/menu" style={{textDecoration: 'none'}}>
                                <div className="fromus-content-btn">
                                    View Our Menu
                                </div>
                            </Link>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} data-aos="fade-up" data-aos-delay="150">
                    <div class="fromus-content">
                        <div class="fromus-content-overlay"></div>
                        <div style={{ width: '100%'}}>
                            <LazyLoadImage 
                                style={{ width: '100%'}}
                                effect="blur"
                                className="fromus-content-image"
                                src={images.liqour} 
                                alt='Liquor Img' 
                            />
                        </div>
                        <div class="fromus-content-details fadeIn-top">
                            <h1>Liquor</h1>
                            <Link to="/menu" style={{textDecoration: 'none'}}>
                                <div className="fromus-content-btn">
                                    View Our Menu
                                </div>
                            </Link>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Stack>

    </section>
  )
}

export default FromUs