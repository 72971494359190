import React from 'react'
import { NavBarOther, LiquorSlider, DailyDeals, Footer } from '../../components/';
import MenuCategories from '../../components/MenuCategories/MenuCategories';

const index = () => {
  return (
    <div className='menu'>
      <NavBarOther />
      <MenuCategories />
      <LiquorSlider />
      <DailyDeals />
      <Footer backgroundColor='rgb(107, 116, 131, 0.05)' />
    </div>
    
  )
}

export default index