import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './SpecialMenu.css';
import { Box, Stack, Typography } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import menuAPI from '../../api/menu';
import { images } from '../../constants';
import Skeleton from '@mui/material/Skeleton';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SpecialMenu = () => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    async function getData() {
        const allMenuData = await menuAPI.getAllMenu();
        const shuffledItems = shuffleArray(allMenuData.data);
        const randomItems = shuffledItems.slice(0, 8);
        setSelectedItems(randomItems);
        setIsLoading(false);
        console.log(allMenuData);
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <section className='container special-menu-section'>
            <Stack maxWidth='100%'>
                <div className='section-heading'>
                    <h2 className='subtitle' data-aos="fade-up">
                        Our Special
                    </h2>
                    <h1 className='title' data-aos="fade-up">
                        Special Menu
                    </h1>
                </div>

                <div className='backgroung-img1' data-aos="fade-left" data-aos-delay="300">
                    <img src={images.bg4} alt='backgroung img' />
                </div>

                {selectedItems.length !== 0 ? (
                    <Stack sx={{ justifyContent: 'space-evenly', alignItems: 'start', flexWrap: 'wrap', gap: { xs: 3, sm: 4, md: 5 }, pt: { xs: 2, sm: 3, md: 4 } }} direction={{ xs: 'column', sm: 'row', md: 'row' }}  data-aos="fade-up">
                        {isLoading ? (
                            // Show Skeletons while data is loading
                            Array.from({ length: 6 }).map((_, idx) => (
                                <Box className='special-menu-card' key={idx}>
                                    <Skeleton variant="rectangular" height={50} sx={{ borderRadius: '5px' }} />
                                    <Skeleton variant="text" width="60%" />
                                </Box>
                            ))
                        ) : (
                            // Render the actual Special Menu Cards
                            selectedItems.map((item, idx) => (
                                <Box className='special-menu-card' key={idx}>
                                    <Stack className='special-menu-head' direction='row' sx={{ alignItems: 'center', gap: 4 }}>
                                        <Box className='special-menu-title'>
                                            <h4>{item.name}</h4>
                                        </Box>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <hr className='special-menu-breakline' />
                                        </Box>
                                        <Box className='special-menu-price'>
                                            <h3>${parseFloat(item.price).toFixed(2)}</h3>
                                        </Box>
                                    </Stack>
                                    <div className='special-menu-desc'>
                                        <p>{item.description}</p>
                                    </div>
                                </Box>
                            ))
                        )}
                        <Link to='/menu' style={{
                            textDecoration: 'none',
                        }}>
                            <div className='special-menu-btn' data-aos="fade-up">
                                <p>View All</p> <EastIcon />
                            </div>
                        </Link>
                    </Stack>
                ) : (
                    <Stack
                        sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: -2 }}
                    >
                        <LazyLoadImage 
                            effect="blur"
                            style={{
                                width: '250px',
                            }}
                            src={images.Empty} 
                            alt='empty' 
                        />
                        <Typography
                            variant="subtitle1"
                            sx={{ color: 'var(--gray-color)', textAlign: 'center', opacity: 0.6}}
                        >
                            Menu items are not available!
                        </Typography>
                    </Stack>
                )}

                <div className='backgroung-img2'>
                    <img src={images.bg9} alt='backgroung img' />
                </div>
            </Stack>
        </section>
    );
}

export default SpecialMenu;
