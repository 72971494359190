import React, { useState, useEffect, useRef } from 'react';
import './CategoryButton.css';
import { images } from '../../../constants';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const CategoryButton = ({ data, onCategorySelection, selectedType }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCategorySelection = (category) => {
    setSelectedCategory(category);
    setIsOpen(false);
    if (category === 'All') {
      onCategorySelection(''); // Pass an empty string to indicate no specific category is selected
    } else {
      onCategorySelection(category);
    }
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setSelectedCategory('All'); // Reset selectedCategory when selectedType changes
  }, [selectedType]);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className="category-button" ref={dropdownRef}>
      <button onClick={toggleDropdown}>
        {selectedCategory}
        <KeyboardArrowDownIcon className="down-arrow" />
      </button>
      {isOpen && (
        <ul>
          <li
            className={selectedCategory === 'All' ? 'selected' : ''}
            onClick={() => handleCategorySelection('All')}
          >
            All
          </li>
          {data.map((category, index) => (
            <li
              key={index}
              className={selectedCategory === category ? 'selected' : ''}
              onClick={() => handleCategorySelection(category)}
            >
              {category}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CategoryButton;
