import React from "react";
import { Rating } from "@mui/material";
import "./TestimonialCardStyles.css"; 
import { images } from '../../constants';

const TestimonialCard = ({ data }) => {

  const CustomEmptyIcon = () => (
    <img src={images.star} alt='star' className="star" id="empty"/>
  );

  const CustomIcon = () => (
    <img src={images.filledStar} alt='star' className="star"/>
  );

  return (
    <div className="testimonial-card">
      <div className="testimonial-card-content">
        <div className="rating">
          <Rating
            value={data.rating}
            readOnly
            style={{
              gap: 10,
            }}
            emptyIcon={<CustomEmptyIcon />} 
            icon={<CustomIcon />} 
          />
        </div>
        
        <div className="review">
          {data.review}
        </div>
        <div className="name">
          {data.name}
        </div>
        <div className="position">
          {data.position}
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
