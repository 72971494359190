import React from 'react'
import { Box } from "@mui/material";

import { NavBarOther, Footer ,AboutUs } from '../../components';

const index = () => {
  return (
    <Box>
      <NavBarOther />
      <AboutUs />
      <Footer backgroundColor='rgb(107, 116, 131, 0.05)' />
    </Box>
  )
}

export default index