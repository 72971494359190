import { Box, Stack } from '@mui/material'
import './SideNav.css'
import { images } from '../../../constants'
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import LiquorIcon from '@mui/icons-material/Liquor';
import { Link } from 'react-router-dom';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SideNav = ({ selectedType, setSelectedType }) => {

    // console.log(selectedType);
    
  return (
    <Stack className='admin-sidebar' sx={{ alignItems: '', flexDirection: { md: 'column' }, width: { xs: '100%', sm: 'fit-content', md: 'fit-content', lg: 'fit-content' }, height: { xs: 'fit-content', sm: '100vh', md: '100vh' }}}>
        <Box className='admin-sidebar-top'>
            <div className='admin-sidebar-logo'>
                <Link to='/' style={{textDecoration: 'none'}}>
                    {/* <img src={images.AdminLogo} alt='RHW Logo' /> */}
                    <LazyLoadImage 
                        effect="blur"
                        className="img"
                        src={images.AdminLogo} 
                        alt='RHW Logo' 
                    />
                </Link>
            </div>
            <hr className='admin-sidebar-breakline' />
            <Stack className='admin-sidebar-menu' sx={{ justifyContent: 'center', flexDirection: { xs: 'row', sm: 'column', md: 'column', lg: 'column' }, p: { xs: '10px 10px', sm: '16px 20px', md: '16px 20px', lg: '16px 20px' } }}>
                <button 
                    className='admin-sidebar-btn'
                    onClick={() => setSelectedType('food')}
                    style={{ 
                        background: 'food' === selectedType && 'var(--secondary-color)',
                        color: 'food' === selectedType && 'var(--white-color)'
                    }}
                >
                    <RamenDiningIcon 
                        style={{ color: 'food' === selectedType && 'var(--white-color)', transition: 'all 0.3s ease-in-out' }} 
                        className='admin-sidebar-btn-icon'
                    />
                    Food Menu
                </button>
                <button 
                    className='admin-sidebar-btn'
                    onClick={() => setSelectedType('liquor')}
                    style={{ 
                        background: 'liquor' === selectedType && 'var(--secondary-color)',
                        color: 'liquor' === selectedType && 'var(--white-color)'
                    }}
                >
                    <LiquorIcon 
                        style={{ color: 'liquor' === selectedType && 'var(--white-color)', transition: 'all 0.3s ease-in-out' }}
                        className='admin-sidebar-btn-icon'
                    />
                    Liquor Menu
                </button>
            </Stack>
        </Box>

        <Box sx={{ flexGrow: 1 }} />

        <Box className='admin-sidebar-bottom' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={images.PoweredByDH} alt='DecHorizon' />
        </Box>
    </Stack>
  )
}

export default SideNav