/* eslint-disable import/no-anonymous-default-export */
import AdminLogo from '../assets/images/Roadwings-logo.png';
import AppLogo from '../assets/images/Roadwings-logo2.png';
import Logo from '../assets/images/Roadwings-logo3.png';
import TextLogo from '../assets/images/Roadwings-Textlogo.png';
import PoweredByDH from '../assets/images/PoweredByDecHorizon.png';
import Loading from '../assets/images/loading.gif';
import Empty from '../assets/images/empty.png';

import facebook from '../assets/images/Facebook.png';
import instagram from '../assets/images/Instagram.png';
import twitter from '../assets/images/Twitter.png';

import UberEats from '../assets/images/uber-eats.png';
import Doordash from '../assets/images/doordash.png';
import SkipTheDishes from '../assets/images/skip-the-dishes.png';

import heroImg from '../assets/images/hero-img.png';
import aboutImg from '../assets/images/about-img.png';
import dish1 from '../assets/images/dish-1.png';
import dish2 from '../assets/images/dish-2.png';
import dish3 from '../assets/images/dish-3.png';
import dish4 from '../assets/images/dish-4.png';
import dish5 from '../assets/images/dish-5.png';

import bgRound from '../assets/images/bg-round.png';
import bg1 from '../assets/images/bg.png';
import bg2 from '../assets/images/bg-2.png';
import bg3 from '../assets/images/bg-3.png';
import bg4 from '../assets/images/bg-4.png';
import bg5 from '../assets/images/bg-5.png';
import bg6 from '../assets/images/bg-6.png';
import bg7 from '../assets/images/bg-7.png';
import bg8 from '../assets/images/bg-8.png';
import bg9 from '../assets/images/bg-9.png';
import bg10 from '../assets/images/bg-10.png';
import bg11 from '../assets/images/bg-11.png';
import bg12 from '../assets/images/bg-12.png';
import footerbg from '../assets/images/footer-bg.png';

import testimoial1 from '../assets/images/testimonial-1.jpg';
import testimoial2 from '../assets/images/testimonial-2.jpg';
import testimoial3 from '../assets/images/testimonial-3.jpg';
import testimoial4 from '../assets/images/testimonial-4.jpg';

import previousIcon from '../assets/images/previous-icon.png';
import nextIcon from '../assets/images/next-icon.png';
import AdminIcon from '../assets/images/admin-outlined-icon.svg';

import star from '../assets/images/star.png';
import filledStar from '../assets/images/star-filled.png';

import liqour from '../assets/images/liqour2.png';
import wine1 from '../assets/images/wine-1.jpg';
import wine2 from '../assets/images/wine-2.jpg';
import wine3 from '../assets/images/wine-3.jpg';
import beer1 from '../assets/images/beer-1.jpg';
import beer2 from '../assets/images/beer-2.jpg';
import beer3 from '../assets/images/beer-3.jpg';
import cocktail1 from '../assets/images/cocktail-1.jpg';
import cocktail2 from '../assets/images/cocktail-2.jpg';
import cocktail3 from '../assets/images/cocktail-3.jpg';

import leftArrow from '../assets/images/left-arrow.png';
import rightArrow from '../assets/images/right-arrow.png';

import food from '../assets/images/food.png';
import food1 from '../assets/images/food-1.jpg';
import food2 from '../assets/images/food-2.jpg';
import food3 from '../assets/images/food-3.jpg';
import OurStory1 from '../assets/images/our-story-1.png';
import OurStory2 from '../assets/images/our-story-2.png';
import DeliciousRecipes1 from '../assets/images/delicious-recipes-1.png';
import DeliciousRecipes2 from '../assets/images/delicious-recipes-2.png';

import MenuItem1 from '../assets/images/menu-item-1.png';

import EditIcon from '../assets/images/edit-icon.png';
import DeleteIcon from '../assets/images/delete-icon.png';

import PlusIcon from '../assets/images/plus-icon.png';
import DownArrow from '../assets/images/arrow-down-icon.png';

export default { 
    AdminLogo, AppLogo, Logo, TextLogo, PoweredByDH, Loading, Empty,
    facebook, instagram, twitter, UberEats, Doordash, SkipTheDishes,
    heroImg, aboutImg, dish1, dish2, dish3, dish4, dish5,
    bgRound, bg1, bg2,  bg3, bg4, bg5, bg6, bg7, bg8, bg9, bg10, bg11, bg12, footerbg,
    testimoial1, testimoial2, testimoial3, testimoial4,
    previousIcon, nextIcon, AdminIcon,
    star, filledStar,
    liqour,
    wine1, wine2, wine3,
    beer1, beer2, beer3,
    cocktail1, cocktail2, cocktail3,
    leftArrow, rightArrow,
    food, food1, food2, food3, OurStory1, OurStory2, DeliciousRecipes1, DeliciousRecipes2,
    MenuItem1,
    EditIcon, DeleteIcon,
    PlusIcon, DownArrow
};