import React from "react";
import "./Header.css";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';

const Header = () => {
  return (
    
    <div class="header">
      <div class="address-container">
        <LocationOnIcon  style={{ color:"#ffffff" }}/>
        <p class="address">2181 Lawrence Ave E, Toronto, ON M1P 2P5</p>
      </div>
      <div class="phone-number-container">
        <PhoneIcon style={{ color:"#ffffff" }}/>
        <p class="phone-number">+1 (647) 458-0939</p>
      </div>
    </div>
   
  );
};

export default Header;
