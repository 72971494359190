import React from 'react'
import './Loader.css'
import { images } from '../../constants'

const Loader = () => {
  return (
    <div className="loader-container">
      <img src={images.Loading} alt="" className="loader" />
    </div>
  )
}

export default Loader