import React from "react";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { images } from '../../constants';
import TestimonialCard from "./TestimonialCard";
import TestimonialData from "./TestimonialData";
import "./TestimonialStyle.css";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const Testimonial = () => {

  const slideProperties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    prevArrow:<div className="icon-wrapper">
                <ArrowBackIosNewIcon className="prev-icon" sx={{ stroke: "#ffffff", strokeWidth: 1 }}/>
              </div>,
    nextArrow:<div className="icon-wrapper">
                <ArrowBackIosNewIcon className="next-icon" sx={{ stroke: "#ffffff", strokeWidth: 1 }}/>
              </div>,
  };
  const indicators = (index) => (<div className="indicator"></div>);

  return (
    <div className="testimonial-container">
      <div id='background-img1'>
         <img src={images.bg8} alt='background img' />
       </div>
       <div className='section-heading'>
         <h2 className='subtitle' data-aos="fade-up">
           Customer
         </h2>
         <h1 className='title' id="title" data-aos="fade-up">
           Testimonial
         </h1>
       </div>
      <Slide {...slideProperties} indicators={indicators}>
        {TestimonialData.map((testimonial, index) => (
          <div className="each-slide" key={index} data-aos="fade-up">
            <div className="testimonial-card-container">
              <div className="testimonial-image">
                <img src={testimonial.image} alt={testimonial.name} border="0" />
              </div>
              <TestimonialCard data={testimonial} />
            </div>
          </div>
        ))}
      </Slide>
      <div id='background-img2' data-aos="fade-right" data-aos-delay="300">
        <img src={images.bg7} alt='background img' />
      </div>
    </div>
  );
};

export default Testimonial;
