import React from 'react';
import "./Footer.css";

import {images} from "../../constants";
import { Stack, Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom';


const Footer = ({ backgroundColor }) => {
    const currentYear = new Date().getFullYear();

    return (
        <section className='container footer-section' style={{ backgroundColor: backgroundColor }}>
            <Stack maxWidth='100%'>
                <Grid className='footer-topcontent-box' container spacing={{ md: 4, lg: 4 }}>
                    <Grid className='footer-company-grid' item xs={12} sm={12} md={5} lg={5} direction='column'>
                        <Stack className='footer-company' sx={{ justifyContent: 'start', gap: 1}}>
                            <img className='footer-company-logo' src={images.Logo} alt='Logo'/>
                            <p>Savor culinary excellence at Roadhouse Wings where flavors meet passion. Enjoy delectable wings, curated menu, 
                                and premium liquor for an unforgettable dining journey. <Link to='/about-us' style={{}}><a>Learn more</a></Link>
                            </p>
                        </Stack>
                        <div className='footer-opening-hours'>
                            <h3>OPENING HOURS</h3>
                            <Stack sx={{ justifyContent: 'start', gap: { xs: 2, sm: 6, md: 4, lg: 4 } }} direction='row'>
                                <Box>
                                    <p>Monday - Friday</p>
                                    <p>8:00 am to 9:00 pm</p>
                                </Box>
                                <Box>
                                    <p>Saturday</p>
                                    <p>8:00 am to 9:00 pm</p>
                                </Box>
                                <Box>
                                    <p>Sunday</p>
                                    <p>CLOSED</p>
                                </Box>
                            </Stack>
                        </div>
                    </Grid>

                    <Grid className='footer-quicklink-grid' item xs={6} sm={4} md={2} lg={2}>
                        <h4>Quick links</h4>
                        <Stack sx={{ justifyContent: 'start', gap: { xs: 1, sm: 1, md: 1.5, lg: 1.5 }, pt: '14px' }}>
                            <Link to='/' style={{textDecoration: 'none'}}>
                                <p className='footer-link'>Home</p>
                            </Link>
                            <Link to='/menu' style={{textDecoration: 'none'}}>
                                <p className='footer-link'>Menus</p>
                            </Link>
                            <Link to='/about-us' style={{textDecoration: 'none'}}>
                                <p className='footer-link'>About Us</p>
                            </Link>
                            <Link to='/contact-us' style={{textDecoration: 'none'}}>
                                <p className='footer-link'>Contact Us</p>
                            </Link>
                        </Stack>
                    </Grid>

                    <Grid className='footer-contact-grid' item xs={6} sm={5} md={3} lg={3}>
                        <h4>Contact</h4>
                        <Stack sx={{ justifyContent: 'start', gap: 1, pt: '14px' }}>
                            <p>2181 Lawrence Ave E, Toronto, ON M1P 2P5</p>
                            <a href='tel:+16474580939'>
                                <p className='footer-link'>+1 (647) 458-0939</p>
                            </a>
                        </Stack>
                    </Grid>

                    <Grid className='footer-socialmedia-grid' item xs={12} sm={3} md={2} lg={2}>
                        <h4>Follow us</h4>
                        <Stack sx={{ justifyContent: 'start', gap: 2, pt: { sm: '20px', md: '20px', lg: '20px' } }} direction='row'>
                            <a href='/'>
                                <div className='footer-social-icon'>
                                    <img src={images.facebook} alt='facebook' />
                                </div>
                            </a>
                            <a href='/'>
                                <div className='footer-social-icon'>
                                    <img src={images.instagram} alt='instagram' />
                                </div>
                            </a>
                            <a href='/'>
                                <div className='footer-social-icon'>
                                    <img src={images.twitter} alt='twitter' />
                                </div>
                            </a>
                        </Stack>
                    </Grid>
                </Grid>

                <div className='footer-divider' />

                <Stack className='footer-copyrights' sx={{ width: '100%', justifyContent: { xs: 'center', sm: 'center', md: 'space-between' }}} direction={{ md: 'row', lg: 'row' }}>
                    <p>© {currentYear} Roadhouse Wings. All Right Reserved.</p>
{/*                     <p>Made with ♡ by 
                        <a href='https://dechorizon.com' target='_blank'> DecHorizon Technologies</a>
                    </p> */}
                </Stack>
            </Stack>

            <div className='backgroung-img1'>
                <img src={images.footerbg} alt='backgroung img' />
            </div>

        </section>
    );
};

export default Footer;
