import React, { useState, useEffect } from 'react';
import './DailyDealsStyle.css';
import DailyDealsCard from './DailyDealsCard';
import { images } from '../../constants';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import menuAPI from '../../api/menu';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import Skeleton from '@mui/material/Skeleton';

const DailyDeals = () => {
  const [MenuData, setMenuData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function getData() {
    try {
      const allMenuData = await menuAPI.getAllMenu();
      setMenuData(allMenuData.data);
      console.log(allMenuData);
    } catch (error) {
      console.error('Error fetching menu data:', error);
      setMenuData([]);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const dailyDealsData = MenuData.filter((item) => item.day !== null && item.day !== 'null' );

  useEffect(() => {
    if (dailyDealsData.length === 0) {
      setIsLoading(true);
    }
    else {
      setIsLoading(false);
    }
  },[dailyDealsData]);

  const responsiveSettings = [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 0, // Mobile screens
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  const slideProperties = {
    prevArrow: (
      <div className="button-wrapper">
        <ArrowBackIosRoundedIcon
          className="prev-icon"
          sx={{ stroke: "#ffffff", strokeWidth: 1 }}
        />
      </div>
    ),
    nextArrow: (
      <div className="button-wrapper">
        <ArrowBackIosRoundedIcon
          className="next-icon"
          sx={{ stroke: "#ffffff", strokeWidth: 1 }}
        />
      </div>
    ),
  };

  return (
    <>
    {dailyDealsData.length !== 0 && 
      <div className='daily-deals-container' data-aos="fade-up">
        <div className='section-heading'>
          <h2 className='subtitle' data-aos="fade-up">
            Daily Special
          </h2>
          <h1 className='title' id='title' data-aos="fade-up">
            Daily Deals
          </h1>
        </div>
        {isLoading ? ( 
          <div className='daily-deals-card-container-loading'>
            <Skeleton variant='rect' width={400} height={200} />
            <Skeleton variant='rect' width={400} height={200} />
            <Skeleton variant='rect' width={400} height={200} />
          </div>
        ) : (
          <div className='daily-deals-card-container'>
            <Slide slidesToScroll={2} slidesToShow={2} responsive={responsiveSettings} {...slideProperties}>
              {dailyDealsData.map((item, index) => {
                return (
                  <div className='daily-deals-card-box' key={index}>
                    <DailyDealsCard data={item} />
                  </div>
                );
              })}
            </Slide>
          </div>
        )}
        <div className='background-image' data-aos="zoom-in-right" data-aos-delay="200">
          <img src={images.bg7} alt='background img' />
        </div>
      </div>
    }
    </>
  );
};

export default DailyDeals;
