import React, { useState, useEffect, useContext } from "react";
import { Box, Stack, Typography } from "@mui/material";
import ReplayIcon from '@mui/icons-material/Replay';

import "./Dashboard.css";
import menuAPI from "../../../api/menu";
import liquorAPI from "../../../api/liquor";
import { AuthContext } from "../../../AuthContext";
import { useNavigate } from "react-router-dom";

import {
  AdminTopBar,
  AdminSideNav,
  Table,
  CategoryButton,
  AddNewItemButton,
} from "../../../components";

export default function Dashboard() {
  // const menuData = [
  //   {
  //     id: 1,
  //     name: "Chicken Burger",
  //     category: "food1",
  //     description:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis semper sapien. Sed vitae quam sed nunc ultricies fermentum. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi.",
  //     price: 10,
  //     image:
  //       "https://images.unsplash.com/photo-1611432546164-5b7bcead0b7b?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Ym9yZGVyJTIwYnVyZ2VyfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80",
  //   },
  //   {
  //     id: 2,
  //     name: "Chicken Pizza",
  //     category: "food2",
  //     description:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis semper sapien. Sed vitae quam sed nunc ultricies fermentum. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi.",
  //     price: 10,
  //     image:
  //       "https://images.unsplash.com/photo-1611432546164-5b7bcead0b7b?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Ym9yZGVyJTIwYnVyZ2VyfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80",
  //   },
  //   {
  //     id: 3,
  //     name: "Veg Burger",
  //     category: "food3",
  //     description:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis semper sapien. Sed vitae quam sed nunc ultricies fermentum. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi.",
  //     price: 10,
  //     image:
  //       "https://images.unsplash.com/photo-1611432546164-5b7bcead0b7b?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Ym9yZGVyJTIwYnVyZ2VyfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80",
  //   },
  // ];

  // const liquorData = [
  //   {
  //     id: 1,
  //     name: "Beer",
  //     category: "liquor1",
  //     description:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis semper sapien. Sed vitae quam sed nunc ultricies fermentum. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi.",
  //     price: 10,
  //     image:
  //       "https://images.unsplash.com/photo-1611432546164-5b7bcead0b7b?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Ym9yZGVyJTIwYnVyZ2VyfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80",
  //   },
  //   {
  //     id: 2,
  //     name: "Wine",
  //     category: "liquor2",
  //     description:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis semper sapien. Sed vitae quam sed nunc ultricies fermentum. Nulla facilisi. Nulla facilisi. Nulla facilisi. Nulla facilisi.",
  //     price: 10,
  //     image:
  //       "https://images.unsplash.com/photo-1611432546164-5b7bcead0b7b?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Ym9yZGVyJTIwYnVyZ2VyfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80",
  //   },
  // ];
  const { isLoggedIn } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [selectedType, setSelectedType] = useState("food");
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleCategorySelection = (category) => {
    setSelectedCategory(category);
  };

  const [MenuData, setMenuData] = useState([]);
  const [LiquorData, setLiquorData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  function refreshTable() {
    getData();
  }

  function handleRefresh() {
    window.location.reload();
  }

  // console.log(selectedType);
  async function getData() {
    try {
      const allMenuData = await menuAPI.getAllMenu();
      const allLiquorData = await liquorAPI.getAllLiquor();
  
      setMenuData(allMenuData.data);
      setLiquorData(allLiquorData.data);
      
      if (selectedType === "food") {
        setData(allMenuData.data);
      } else {
        setData(allLiquorData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    if (selectedType === "food") {
      // setData(menuData);
      setData(MenuData);
    } else {
      // setData(liquorData);
      setData(LiquorData);
    }
  }, [selectedType, MenuData, LiquorData]);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     navigate("/admin/login");
  //   }
  // }, [isLoggedIn, navigate]);

  const categories = [...new Set(data.map((item) => item.category))];
  const filteredData = !selectedCategory
    ? data
    : data.filter((item) => item.category === selectedCategory);
  // console.log(filteredData);

  return (
    <Stack
      direction={{ xs: "column", sm: "row", md: "row", lg: "row" }}
      sx={{ height: { sm: "100vh", md: "100vh", lg: "100vh" } }}
      className="dashboard-container"
    >
      <AdminSideNav
        selectedType={selectedType}
        setSelectedType={setSelectedType}
      />
      <Box width="100%">
        <AdminTopBar />
        <div className="dashboard-buttons">
          <div className="dashboard-left-buttons">
            <CategoryButton
              data={categories}
              onCategorySelection={handleCategorySelection}
              selectedType={selectedType}
            />
            <div className="refresh-button">
              <button onClick={handleRefresh}>
                <Typography>Refresh</Typography>
                <ReplayIcon className="refresh-icon" />
              </button>
            </div>
          </div>
          <AddNewItemButton
            categories={categories}
            selectedType={selectedType}
          />
        </div>
        <Table
          data={selectedCategory === "All" ? data : filteredData}
          selectedType={selectedType}
          refresh={refreshTable}
        />
      </Box>
    </Stack>
  );
}
