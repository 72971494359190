import React, { useEffect } from 'react';
import './Confirm.css';
import menuAPI from '../../../api/menu';
import LiquorAPI from '../../../api/liquor';

const Confirm = ({ setIsConfirm, data, type, refresh }) => {
  const handleClose = () => {
    setIsConfirm(false);
  };

  const handleDelete = () => {
    console.log('Delete', data.id);
    console.log(type);
      deleteData(data.id);
    setIsConfirm(false);
  };


  async function deleteData(id){
    if(type === 'food'){
  const delData =  await menuAPI.deleteMenu(id);
  refresh && refresh();
    }
    else{
      const delLiquorData =  await LiquorAPI.deleteLiquor(id);
      refresh && refresh();
    }
  }

  const handleOutsideClick = (event) => {
    const confirmBox = document.querySelector('.confirm-box');
    if (confirmBox && !confirmBox.contains(event.target)) {
      setIsConfirm(false);
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleOutsideClick);

    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className='confirm'>
      <div className='confirm-box'>
        <h1>Are you sure you want to delete this item?</h1>
        <div className='confirm-btn'>
          <button id='delete' onClick={handleDelete}>Delete</button>
          <button id='cancel' onClick={handleClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
