import React from 'react';
import { Box, Stack } from "@mui/material";

import { SpecialDishes, Hero, Header, Testimonial, NavBar, Footer, SpecialMenu, OurStory, DeliciousRecipes, GoogleMap } from '../../components';


const index = () => (
    <Box
        sx={{ overflowX: 'hidden' }}
    >
        <Header />
        <NavBar />
        <Hero />
        <SpecialDishes />
        <OurStory />
        <SpecialMenu />
        <DeliciousRecipes />
        <Testimonial />
        <section className='our-location-section'>
            <div className='section-heading'>
                <h2 className='subtitle' data-aos="fade-up">
                    Our
                </h2>
                <h1 className='title' data-aos="fade-up">
                    Location
                </h1>
            </div>
            <Stack>
                <GoogleMap />
            </Stack>
        </section>
        <Footer />
    </Box>
)

export default index