import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import { AuthContext } from './AuthContext';
import './assets/styles/styles.css';

import { Home, Menu, AboutUs, ContactUs, Dashboard, Login } from './pages';
import { Loader } from './components';
import MenuAPI from './api/menu';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const { isLoggedIn } = useContext(AuthContext);

  async function fetchData() {
    const allMenuData = await MenuAPI.getAllMenu();

    if (allMenuData !== null) {
      setIsDataFetched(true);
    }
  }

  useEffect(() => {
    fetchData();
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={isLoading ? <Loader /> : <Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/contact-us" element={<ContactUs />} />
          {/* Check if the user is authenticated before rendering the Dashboard */}
          <Route
            path="/admin/dashboard"
            element={isLoggedIn ? <Dashboard /> : <Navigate to="/admin/login" />}
          />
          <Route path="/admin/login" element={<Login />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default App;
