import React, { useState, useEffect, useRef } from "react";
import { Stack } from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import "./TopBar.css";
import { images } from "../../../constants";
import axios from "axios";
import { Link } from "react-router-dom";

const TopBar = () => {
  const [greeting, setGreeting] = useState("");
  const dropdownRef = useRef(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const currentHour = new Date().getHours();

    let newGreeting;

    if (currentHour >= 3 && currentHour < 12) {
      newGreeting = "Good Morning";
    } else if (currentHour >= 12 && currentHour < 15) {
      newGreeting = "Good Afternoon";
    } else if (currentHour >= 15 && currentHour < 20) {
      newGreeting = "Good Evening";
    } else {
      newGreeting = "Good Night";
    }
    setGreeting(newGreeting);
  }, []);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const removeToken = () => {
    localStorage.removeItem("jwtToken");
  }

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <Stack
      className="admin-topbar"
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" },
      }}
    >
      <h3 className="admin-topbar-greeting">{greeting}, Roadhouse Admin</h3>

      <div className="admin-account" ref={dropdownRef}>
        <Dropdown
          trigger={
            <div className="admin-account-dropdown">
              <img src={images.AdminIcon} alt="admin" />
              <span>Roadhouse Admin</span>
              <ExpandMoreOutlinedIcon
                style={{ color: "var(--gray-color)", fontSize: "18px" }}
              />
            </div>
          }
          open={open}
          handleOpen={handleOpen}
          menu={[
            // <div className="admin-dropdown-list">
            //   <Settings style={{ color: "var(--gray-color)" }} />
            //   Setting
            // </div>,
            <div className="admin-dropdown-list">
              <Link
                to="/admin/login"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  display: "flex",
                  alignItems: "center",
                  gap: 2
                }}
                onClick={removeToken}
              >
                <Logout style={{ color: "var(--gray-color)" }} />
                Logout
              </Link>
            </div>,
          ]}
        />
      </div>
    </Stack>
  );
};

const Dropdown = ({ trigger, open, handleOpen, menu }) => {
  return (
    <div className="dropdown-admin">
      {React.cloneElement(trigger, {
        onClick: handleOpen,
      })}
      {open ? (
        <ul className="menu-admin">
          {menu.map((menuItem, index) => (
            <li key={index} className="menu-item">
              {React.cloneElement(menuItem, {
                onClick: () => {
                  handleOpen(false);
                },
              })}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default TopBar;
