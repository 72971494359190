// file has testimonial data for the Testimonial component which has name, position, review, image and rating to use in a map function to render the data in the component
import { images } from '../../constants';

const TestimonialData = [
    {
        id: 1,
        name: 'Kandis Gill',
        position: 'Customer',
        review: 'Food quality is reasonable for what is offered. I would rate most of the staff 5 however there is always one. Sociable clientele. Lots of fun for karaoke.',
        image: 'https://lh3.googleusercontent.com/a/AAcHTtfwuTGD-iByQ7y8-X0tVJjs16SkQXpmmKWvXF8LNm87=w75-h75-p-rp-mo-br100',
        rating: 4
    },
    {
        id: 2,
        name: 'Jessica Jaikissoon',
        position: 'Customer',
        review: `I take the bus to pass here daily on my way to work, never thought about trying, it was uber recommended with a bogo deal.. I'm eating it right now..I'm pretty darn impressed!!! The fries with  the sauces onions jalapeño tomatoes, fab. But the basic cheeseburger, I was like wow. I'm pretty picky with meat but it was flavourful, cooked to perfection.`,
        image: 'https://lh3.googleusercontent.com/a-/AD_cMMTeqxeV0ribDt9F_kfUv-H6iB5EPB34WPXKDrkSpDPzPCno=w75-h75-p-rp-mo-ba4-br100',
        rating: 5
    },
    {
        id: 3,
        name: 'Brandon Ramberack',
        position: 'Customer',
        review: 'Got uber eats from this restaurant tonight. Was very impressed with how they packed everything and all the stuff provided in terms of delivery utensils such as napkins and condiments!! Food was hot and fresh. Enjoyed the wings I got along with the Gar Par fries.',
        image: 'https://lh3.googleusercontent.com/a-/AD_cMMTdWw8pW-aISYuShdl6INP9VL9enqVItc_bfAea7_pFJlQ=w75-h75-p-rp-mo-ba6-br100',
        rating: 5
    },
    {
        id: 4,
        name: 'Tristen D',
        position: 'Customer',
        review: 'I did an Uber Eats order of 2lbs of Wings but when it came I only received 1lbs, so I contacted the store and the lady who I spoke with was very helpful she delivered the extra wings to my door very quickly also with free complementary fries and onion rings. I really appreciate that alot! Amazing service!!',
        image: 'https://lh3.googleusercontent.com/a/AAcHTtdCjQ0vGkREIltgQK3Csl17MeX9C7vHtLu0SN3rANZi=w75-h75-p-rp-mo-br100',
        rating: 5
    },
    {
        id: 5,
        name: 'Brush Gliddon',
        position: 'Customer',
        review: 'What the hell am I doing in this place? Mama Told Me Not to Come. The beer is cold, though, and the food tastes good and is reasonably priced.  The Juke box carries the latest Tunes as well as the oldie Goldies. The bartenders are nice and sweet. Have a good time.',
        image: 'https://lh3.googleusercontent.com/a/AAcHTtejN6GEr9M-Wv4xM-5qltrdMqxMMFTKz1Z5nGNCJxkE=w75-h75-p-rp-mo-ba4-br100',
        rating: 4
    },
    {
        id: 6,
        name: 'Joe Schmoe',
        position: 'Customer',
        review: 'Had the wings special with fries. Great deal! The food quality was great along with the waitress which was was very nice and accomdating. This place is a gem for the area. Going to be in my regular rotation probably go atleast once a week.',
        image: 'https://lh3.googleusercontent.com/a-/AD_cMMQmmGc7tO-74DdDyT8jpgOx3ZnUSLassf4kZ9DEZnOvpQg=w75-h75-p-rp-mo-ba3-br100',
        rating: 5
    },
    {
        id: 7,
        name: 'Jackson King',
        position: 'Customer',
        review: 'This place has amazing wings...great couples deal that included 2 pounds of wings, two pints of beer and large fries for 29 bucks. Seriously good quality food. Must try',
        image: 'https://lh3.googleusercontent.com/a/AAcHTtenWcdwP8yrLH2drFg-kzeMQtO9RZdZ6iBNr6qxPU4p=w75-h75-p-rp-mo-br100',
        rating: 5
    },
    {
        id: 8,
        name: 'Marvin Charles',
        position: 'Customer',
        review: 'Ive been going to this bar more than 10 years. Great staff, not for the classy people. Costumers have never been a problem, atleast they dont bother me or my friends. Sketchy place if your new.',
        image: 'https://lh3.googleusercontent.com/a-/AD_cMMSotISRTjd96htaVfS1wJkUQ2MxLEcPEPRl2RbXs1jEvSU=w75-h75-p-rp-mo-ba4-br100',
        rating: 4
    }
]

export default TestimonialData;