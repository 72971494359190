import React, { useState, useEffect } from "react";
import { Stack, Skeleton, Typography } from "@mui/material";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import "./SpecialDishes.css";
import menuAPI from "../../api/menu";
import { images } from "../../constants";

const SpecialDishCard = () => {
  const [MenuData, setMenuData] = useState([]);
  const [specialDishes, setSpecialDishes] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getData() {
    try {
      const allMenuData = await menuAPI.getAllMenu();
      if (allMenuData && allMenuData.data) {
        setMenuData(allMenuData.data);
        setLoading(false);
        console.log(allMenuData);
      } else {
        console.error("Error fetching menu data: Data not available");
        setMenuData([]);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching menu data:", error);
      setMenuData([]);
      setLoading(false);
    }
  }
  

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const specialDishesData = MenuData.filter(
      (item) => item.specialDish === true
    );
    console.log(specialDishesData);
    setSpecialDishes(specialDishesData.slice(0, 4));
  }, [MenuData]);

  return (
    <>
    {specialDishes.length !== 0 ? (
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          gap: { xs: 4, md: 5 },
        }}
        direction={{ xs: "column", sm: "row", md: "row" }}
      >
        {loading ? (
          <>
            <Skeleton variant="rectangular" width={300} height={200} animation="wave" />
            <Skeleton variant="rectangular" width={300} height={200} animation="wave" />
            <Skeleton variant="rectangular" width={300} height={200} animation="wave" />
            <Skeleton variant="rectangular" width={300} height={200} animation="wave" />
          </>
        ) : (
          specialDishes &&
          specialDishes.map((item, idx) => (
            <div key={idx} className="special-dishes-card" data-aos="fade-up">
              <img src={item.image} alt={item.title} />

              <div className="special-dishes-price" data-aos="zoom-in" data-aos-delay="200">
                <p>${item.price}</p>
              </div>

              <div className="special-dishes-box">
                <div className="special-dishes-content">
                  <h2>{item.name}</h2>
                  <p>{item.description}</p>
                </div>
              </div>
            </div>
          ))
        )}
      </Stack>
    ):(
      <Stack
        sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: -2 }}
      >
        <LazyLoadImage 
          effect="blur"
          style={{
            width: '250px',
          }}
          src={images.Empty} 
          alt='empty' 
        />
        <Typography
          variant="subtitle1"
          sx={{ color: 'var(--gray-color)', textAlign: 'center', opacity: 0.6}}
        >
          No any special dishes available today!
        </Typography>
      </Stack>
    )}
    </>
  );
};

export default SpecialDishCard;
