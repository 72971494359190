import React, { useEffect, useState } from 'react';
import './LiquorSliderCard.css';

const LiquorSliderCard = ({ data }) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
    const timeout = setTimeout(() => {
      setAnimate(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, [data]);

  console.log(data);

  return (
    <div className={`liquor-card ${animate ? 'card-fade-in' : ''}`}>
      <div className="liquor-image-container">
        <img src={data.image} alt="Liquor" className="liquor-image" />
        <div className="price-circle" data-aos="zoom-in" data-aos-delay="200">
          <span className="price">${data.price}</span>
        </div>
      </div>
      <div className="liquor-details-container">
        <div className="liquor-name">{data.name}</div>
        <div className="liquor-description">{data.description}</div>
      </div>
    </div>
  );
};

export default LiquorSliderCard;
