import React, { useEffect, useState } from "react";
import "./Overlay.css";
import menuAPI from "../../../api/menu";
import liquorAPI from "../../../api/liquor";
import { ImageUploadWidget } from "../../../components";
import UploadIcon from "@mui/icons-material/Upload";

const Overlay = ({ setIsOpen, data, title, categories, type, refresh }) => {
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  // const [imgUrl, setImgUrl] = useState("");
  const [imageUrl, setImageUrl] = useState('');
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [day, setDay] = useState("");
  const [specialDish, setSpecialDish] = useState(false);
  const [addNewCategory, setAddNewCategory] = useState(false);
  const [id, setId] = useState("");
  const [nameError, setNameError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [error, updateError] = useState();
  const [newCategory, setNewCategory] = useState("");
  const [isAddingNewCategory, setIsAddingNewCategory] = useState(false);

  var imgUrl;

  const handleCloseOverlay = () => {
    setIsOpen(false);
    setName("");
    setCategory("");
    // setImgUrl("");
    setDescription("");
    setPrice("");
    setDay("");
    setSpecialDish(false);
  };

  const handleOnUpload = (error, result, widget) => {
    if (error) {
      updateError(error);
      widget.close({
        quiet: true,
      });
      return;
    }
    if(result){
      console.log(result);
      // const imageUrl = result?.info?.secure_url;
      imgUrl = result?.info?.secure_url;
      setImageUrl(imgUrl);
      console.log(imgUrl);
    }
  };
  console.log(imageUrl);

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setCategory(selectedCategory);

    // Check if "Add New Category" was selected
    if (selectedCategory === "Add New Category") {
      setIsAddingNewCategory(true);
      setCategoryError(""); // Reset error when changing to input mode
    } else {
      setIsAddingNewCategory(false);
    }
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();

    console.log(imageUrl);
    
    try {
      // Reset error messages
      setNameError("");
      setCategoryError("");
      setPriceError("");

      // Form validation
      let isValid = true;

      if (!name || !name.trim()) {
        setNameError("Please enter a valid name.");
        isValid = false;
      }
      if (!category || (category === "Add New Category" && !newCategory)) {
        setCategoryError("Please select a valid category or enter a new one.");
        isValid = false;
      }

      if (!price || isNaN(price) || parseFloat(price) <= 0) {
        setPriceError("Please enter a valid price.");
        isValid = false;
      }

      if (!isValid) {
        return;
      }

      console.log("Name:", name);
      console.log("Category", category);
      console.log("Image:", imageUrl);
      console.log("Description:", description);
      console.log("Price:", price);
      console.log("Daily Deals:", day);
      console.log("Special Dish:", specialDish);

      const formData = new FormData();
      formData.append("name", name);
      if (isAddingNewCategory) {
        formData.append("category", newCategory);
      } else {
        formData.append("category", category);
      }
      formData.append("image", imageUrl);
      formData.append("description", description);
      formData.append("price", price);
      formData.append("day", day);

      if (title === "Edit") {
        if (type === "food") {
          formData.append("specialDish", specialDish);
        }
        updateData(id, formData);
      } else {
        postData(formData);
      }
      // Clear form fields
      setName("");
      setCategory("");
      // setImgUrl("");
      setImageUrl("");
      setDescription("");
      setPrice("");
      setDay("");
      setSpecialDish(false);

      // Close the overlay
      setIsOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (data) {
      setName(data.name);
      setCategory(data.category);
      // setImgUrl(data.image);
      setImageUrl(data.image);
      // imgUrl = data.image;
      setDescription(data.description);
      setPrice(data.price);
      setId(data.id);
      setDay(data.day);
      setSpecialDish(data.specialDish);
    }
    console.log("DATA", data);
  }, [data]);

  async function postData(formData) {
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });

    var allMenuData = null;
    console.log(type);
    if (type === "food") {
      allMenuData = await menuAPI.postMenu(data);
      console.log(allMenuData);
    } else {
      allMenuData = await liquorAPI.postLiquor(data);
      console.log(allMenuData);
    }
  }

  async function updateData(id, data1) {
    const data = {};
    data1.forEach((value, key) => {
      data[key] = value;
    });

    if (type === "food") {
      const allMenuData = await menuAPI.updateMenu(id, data);
      console.log(allMenuData);
    }
    if (type === "liquor") {
      const allLiquorData = await liquorAPI.updateLiquor(id, data);
      console.log(allLiquorData);
    }
  }

  // const handleOutsideClick = (event) => {
  //   const formElement = document.querySelector(".form");
  //   if (formElement && !formElement.contains(event.target)) {
  //     handleCloseOverlay();
  //   }
  // };

  // useEffect(() => {
  //   if (data) {
  //     setName(data.name);
  //     setCategory(data.category);
  //     setImgUrl(data.imgUrl);
  //     setDescription(data.description);
  //     setPrice(data.price);
  //     setId(data.id);
  //     setDay(data.day);
  //     setSpecialDish(data.specialDish);
  //     console.log(data);
  //   }

  //   window.addEventListener("mousedown", handleOutsideClick);

  //   return () => {
  //     window.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, [data]);

  // save categories to a new array and add 'Add New Category' to the end of the array
  // const categoriesData = categories && [...categories];
  const categoriesData = categories
    ? [...categories, "Add New Category"]
    : ["Add New Category"];

  // console.log(categoriesData);
  const showOnlyEdit = title === "Edit";
  const showOnlyFood = type === "food";
  return (
    <div className="overlay">
      <form className="form" onSubmit={handleSubmit}>
        <h1 className="overlay-title">{title} Form</h1>
        <label>
          Name:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {nameError && <div className="error-message">{nameError}</div>}
        </label>
        <label>
          Category:
          {showOnlyEdit && (
            <input
              type="text"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="category-dropdown"
            />
          )}
          {categories && (
            <div>
              {category !== "Add New Category" ? (
                <select
                  value={category}
                  onChange={handleCategoryChange}
                  className="category-dropdown"
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category}>
                      {category}
                    </option>
                  ))}
                  <option value="Add New Category">Add New Category</option>
                </select>
              ) : (
                <input
                  type="text"
                  placeholder="Enter new category"
                  value={newCategory}
                  onChange={(e) => setNewCategory(e.target.value)}
                  className="category-dropdown"
                />
              )}
              {categoryError && (
                <div className="error-message">{categoryError}</div>
              )}
            </div>
          )}
        </label>

        <div className="upload-image-container">
          <label>
            Image: {imageUrl}
            {imageUrl && (
              <img src={imageUrl} alt="menu item" className="image-preview" />
            )}
            <ImageUploadWidget onUpload={handleOnUpload}>
              {({ open }) => {
                function handleOnClick(e) {
                  e.preventDefault();
                  open();
                }
                return (
                  <button onClick={handleOnClick} id="upload-button">
                    <UploadIcon />
                    Upload
                  </button>
                );
              }}
            </ImageUploadWidget>
            {error && <p>{error}</p>}
          </label>
        </div>

        <label>
          Description:
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </label>
        <label>
          Price:
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
          {priceError && <div className="error-message">{priceError}</div>}
        </label>
        {/* Daily Deals field */}
        {showOnlyEdit && showOnlyFood && (
          <label>
            Daily Deals:
            <select
              value={day}
              onChange={(e) => setDay(e.target.value)}
              className="dailydeals-dropdown"
            >
              <option value="">Select a day</option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
            </select>
          </label>
        )}

        {/* Special Dish field */}
        {showOnlyEdit && showOnlyFood && (
          <div className="special-dish-container">
            <label className="special-dish-label">Special Dish:</label>
            <div className="special-dish-toggle">
              <input
                type="checkbox"
                checked={specialDish}
                onChange={(e) => setSpecialDish(e.target.checked)}
                className="special-dish-checkbox"
              />
              <div
                className={`toggle-button ${specialDish ? "active" : ""}`}
                onClick={() => setSpecialDish(!specialDish)}
              />
            </div>
          </div>
        )}

        <div className="button-group">
          <button type="button" className="cancel" onClick={handleCloseOverlay}>
            Cancel
          </button>
          <button type="submit" className="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Overlay;
