import React from "react";
import { Stack, Container, Box } from "@mui/material";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import './SpecialDishCard';
import { images } from '../../constants';
import SpecialDishCard from './SpecialDishCard';


const SpecialDishes = () => {
    return (
        <Container className="special-dishes-container" maxWidth={{ md: '90vw'}}>
            <Stack >
                <div className='section-heading'>
                    <h2 className='subtitle' data-aos="fade-up">
                        Our Special
                    </h2>
                    <h1 className='title' data-aos="fade-up">
                        Our Special Dishes
                    </h1>
                    <p  data-aos="fade-up">
                        Discover culinary treasures: Unique flavors, premium ingredients crafted for elevated dining.
                    </p>
                </div>

                <div className='backgroung-img1' data-aos="fade-right" data-aos-delay="300">
                    <LazyLoadImage 
                        effect="blur"
                        className="img"
                        src={images.bg9} 
                        alt='backgroung img' 
                    />
                </div>

                <div className='backgroung-img2' data-aos="fade-left" data-aos-delay="300">
                    <LazyLoadImage 
                        effect="blur"
                        className="img"
                        src={images.bg4} 
                        alt='backgroung img' 
                    />
                </div>
                
                <div className="special-dishes">
                    <SpecialDishCard />
                </div>

                <div className='backgroung-img3'>
                    <LazyLoadImage 
                        effect="blur"
                        className="img"
                        src={images.bg10} 
                        alt='backgroung img' 
                    />
                </div>
            </Stack>
        </Container>

    );
}

export default SpecialDishes