import React, { useState } from 'react';
import './Table.css';
import Overlay from '../Overlay/Overlay';
import Confirm from '../Confirm/Confirm';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const Table = ({ data, selectedType, refresh }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleEdit = (item) => {
    setSelectedItem(item);
    setIsEdit(true);
    setIsOpen(true);
    refresh && refresh();
  };

  const handleDelete = (item) => {
    setSelectedItem(item);
    setIsConfirm(true);
    refresh && refresh();
  };
  // console.log(data);

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>No</th>
            <th className='title'>Title</th>
            <th className='price'>Category</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <React.Fragment key={item.id}>
              <tr>
                <td>{index + 1}</td>
                <td className='title'>{item.name}</td>
                <td>{item.category}</td>
                <td className='price'><span id='dollar'>$</span>{item.price.toFixed(2)}</td>
                <td className='btn-group'>
                  <button onClick={() => handleEdit(item)} id='edit'><EditIcon className='edit-icon'/> Edit</button>
                  <button onClick={() => handleDelete(item)} id="delete"><DeleteIcon className='delete-icon'/> Delete</button>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      
      {isEdit && isOpen && (
        <Overlay setIsOpen={setIsOpen} data={selectedItem} title="Edit" type={selectedType} refresh={refresh}/>
      )}
      {isConfirm && (
        <Confirm setIsConfirm={setIsConfirm} data={selectedItem} type={selectedType} refresh={refresh}/>
      )}
    </div>
  );
};

export default Table;
