import React from 'react'
import './DailyDealsCard.css'

const DailyDealsCard = ({data}) => {
  console.log(data);
  return (
    <div className='daily-deals-card'>
        <div className="daily-deals-card-image">
            <img src={data.image} alt={data.day}/>
        </div>
        <div className="daily-deals-card-content">
            <div className="daily-deals-card-title">{data.day}</div>
            <div className="daily-deals-card-description">{data.description}</div>
        </div>
    </div>
  )
}

export default DailyDealsCard;
