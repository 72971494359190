import React, { useState } from 'react';
import './AddNewItemButton.css';
import { images } from '../../../constants';
import Overlay from '../Overlay/Overlay';
import AddIcon from '@mui/icons-material/Add';

const AddNewItemButton = ({ categories,selectedType}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    setIsOpen(true);
  };

  const handleCloseOverlay = () => {
    setIsOpen(false);
  };
  // console.log(selectedType);
  return (
    <div className="add-new-item">
      <button onClick={handleButtonClick}>
        <AddIcon className='plus'/>
        Add New Item
      </button>
      {isOpen && (
        <Overlay
          setIsOpen={setIsOpen}
          onCloseOverlay={handleCloseOverlay}
          data={[]}
          title='Add New Item'
          categories={categories}
          type={selectedType}
        />
      )}
    </div>
  );
};

export default AddNewItemButton;
