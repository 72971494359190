import { Box, Stack } from '@mui/material';
import React, {useState, useEffect}from 'react';

import { images } from '../../constants';
const MenuList = ({ menuData, selectedCategory }) => {
    const [displayCount, setDisplayCount] = useState(4); 
    const [isMobile, setIsMobile] = useState(false);

    const handleViewAllClick = () => {
        setDisplayCount(menuData.filter((data) => data.category === selectedCategory).length);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
  return (
    <Stack 
        className='menu-categories-item-list'
        direction={{ xs: 'column', sm: 'row', md: 'row' }}
        sx={{
            width: '100%',
            // flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'center',
            display: { sm: 'grid' },
            gridTemplateColumns: { sm: 'repeat(2, minmax(0, 1fr))', md: 'repeat(2, minmax(0, 1fr))', lg: 'repeat(3, minmax(0, 1fr))' },
            // alignItems: 'center',
            gap: 4,
            // flexWrap: 'wrap'
        }} 
    >
        {menuData &&
                menuData
                    .filter((data) => data.category === selectedCategory)
                    .slice(0, isMobile ? displayCount : undefined)
                    .map((data) => (
                        <Box
                            // sx={{ width: { xs: '100%', sm: '46%', md: '30%', lg: '30.8%' } }}
                            sx={{ width: { xs: '100%' } }}
                            className='menu-item-card'
                            key={data.id}
                        >
                        <Stack sx={{ justifyContent: 'center', alignItems: 'center', px: '40px', py: '30px' }} direction='column' >
                            <div className='menu-item-img'>
                                <img src={data.image} alt={data.itemName} />
                            </div>
                            <div className='menu-item-text'>
                                <h2>{data.name}</h2>
                                <p>{data.description}</p>
                            </div>
                            <div className='menu-item-price'>
                                <p>${parseFloat(data.price).toFixed(2)}</p>
                            </div>
                        </Stack>
                    </Box>
                ))}
                {isMobile && displayCount < menuData.filter((data) => data.category === selectedCategory).length && (
                <button className="view-all-button" onClick={handleViewAllClick}>
                    View All
                </button>
            )}
    </Stack>
  )
}

export default MenuList