import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemText, Box, Divider, Button, ListItemButton } from '@mui/material';
import { useLocation } from "react-router-dom";
import logo from '../../assets/images/Roadwings-Textlogo.png';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from '@mui/styles';
import "./NavBar.css";
import logoM from '../../assets/images/Roadwings-logo3.png';
import { Link } from "react-router-dom";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const drawerWidth = '100%';
// const navItems = ['Home', 'About', 'Contact'];
const navItems = [
  {
    display: "Home",
    url: "/",
  },
  {
    display: "About",
    url: "/about-us",
  },
  {
    display: "Menus",
    url: "/menu",
  },
  {
    display: "Contact us",
    url: "/contact-us",
  },
];

const useStyles = makeStyles({
  active: {
    color: 'var(--black-color)',
    border: '2px solid #FF9400',
    textAlign: 'start',
    textDecoration: 'none',
    width: '76%',
    borderRadius: '2px',
    height: '0px',
  },
  inactive: {
    color: 'var(--black-color)',
    textDecoration: 'none',
  },

})

const NavbarOther = (props) => {

  const classes = useStyles();
  const location = useLocation();


  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);


  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <LazyLoadImage 
        effect="blur"
        style={{ height: 100, my: 3 }}
        src={logoM} 
        alt='Logo' 
      />
      <Divider />
      <List sx={{ py: 3 }}>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding 
          sx={{
            paddingLeft:'20px',
            paddingTop: '5px',
            '&:hover': {
            '& .MuiListItemText-primary': {
              color: 'var(--black-color)',  // Change the text color on hover
            },
            '& .MuiButtonBase-root': {
              backgroundColor: 'transparent', // Remove the background color on hover
            },
          },}}>
            <Link
              className='navLink'
              to={item.url}
            >
              <ListItemButton sx={{
                textAlign: 'center', }}  >
                <ListItemText primary={item.display} sx={{
                  fontWeight: '500', fontFamily: 'var(--font-primary)',
                  color: location.pathname === item.url ? 'var(--black-color)' : 'var(--gray-color)', 
                }} />
                <div style={{ position: 'absolute', width: '60%', marginBlockStart: '25px' }} className={location.pathname === item.url ? classes.active : null}>
                </div>
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (

    <Box sx={{ display: 'flex' }} style={{ position: 'absolute' }}>
      <AppBar component="nav"
        sx={{
          backgroundColor: 'var(--white-color)', 
          boxShadow: '0px 4px 25px rgba(107, 116, 131, 0.15)', 
          padding: { xs: '0px 10px', sm: '0px 60px', md: '0px 140px', lg: '0px 140px'},
          // '@media (max-width: 1200px)': { padding: '0px 30px', }, 
          height: '80px'
        }} >
        <Toolbar >


          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'block', md: 'block' }, height: '80px'  }}
          >
            <Link to="/">
              {/* <Box
                component="img"
                sx={{ height: 80 }}
                alt="Logo"
                src={logo}
              /> */}
              <LazyLoadImage 
                effect="blur"
                style={{ height: 80 }}
                src={logo} 
                alt='Logo' 
              />
            </Link>

          </Typography>

          <IconButton
            color="black"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { md: 'none' }, mt: 0.5 }}
          >
            <MenuIcon />

          </IconButton>
          <Box sx={{ display: { xs: 'none', md: 'block' }, paddingBlockStart: 0 }}>
            {navItems.map((item, index) => (

              <Link to={item.url} >

                <Button key={index} sx={{
                  fontWeight: '500', fontFamily: 'var(--font-primary)', fontSize: '16px', textTransform: 'none',
                  '&:hover': {
                    color: 'var(--black-color)', // Change the text color on hover
                    backgroundColor: 'transparent',
                  }, marginLeft: '32px', color: location.pathname === item.url ? 'var(--black-color)' : 'var(--gray-color)'
                }}>

                  {item.display}
                  <div style={{ position: 'absolute', marginBlockStart: '25px' }} className={location.pathname === item.url ? classes.active : null}>
                  </div>
                </Button>

              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          anchor="top"
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

      <Toolbar />
    </Box>

  );
};

export default NavbarOther;