import api from "./httpConfig";

const AuthAPI = {

    login : async (formData) =>{
        try {
            const response = await api.post("/auth/login", formData, {
              headers: {
                "Content-Type": "application/json",
              },
            });
            return response;
    }
    catch(err)
    {
      console.log(err);
    }
    },

    register : async (formData) =>{
    try
    {
    const response = await api.post("/user/register",formData, {
        headers: {
            "Content-Type": "application/json",
            },
            });
    return response;
    }
    catch(err)
    {
      console.log(err);
    }
    },

};

export default AuthAPI;