import React from 'react';
import './DeliveryCard.css';
import { Box, Container, Stack, Typography } from '@mui/material';

import { images } from '../../constants';
import LaunchIcon from '@mui/icons-material/Launch';

const DeliveryCard = () => {

    const cardData = [
        { name: 'Uber Eats', url: '', imgUrl: images.UberEats, className: 'delivery-card-box uber-eats', color: '#00C444'},
        { name: 'Doordash', url: '', imgUrl: images.Doordash, className: 'delivery-card-box doordash', color: '#FF2F08'},
        { name: 'SKIP THE DISHES', url: '', imgUrl: images.SkipTheDishes, className: 'delivery-card-box skip-the-dishes', color: '#FF7F00'},
    ]

  return (
    <Stack className='delivery-section' data-aos="fade-up" data-aos-delay="200">
        <Box sx={{ px: { xs: '30px', sm: '90px', md: '180px', lg: '180px'} }}>
            <Stack sx={{ justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', gap: { xs: 4, md: 5 } }} direction='row' >
                {cardData && cardData.map((item, idx) => (
                    <Box key={idx} sx={{ position: 'relative', width: { xs: '100%', sm: '45%', md: '30%' } }}>
                        <div className={item.className}>
                            <div className='external-link-icon'>
                                <LaunchIcon style={{ color: 'rgba(107, 116, 131, 0.6)' }} />
                            </div>
                            <img src={item.imgUrl} alt={item.name} />
                        </div>
                        <Typography variant='body' sx={{ fontSize: 16, fontWeight: 500, color: "#6B7483", textAlign: "center", px: 1 }}>
                            Order with <span style={{ color: item.color, fontWeight: 600 }}>{item.name}</span>
                        </Typography>
                    </Box>
                ))}
            </Stack>
        </Box>
    </Stack>
  )
}

export default DeliveryCard