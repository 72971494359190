import React from 'react';
import './OurStory.css';
import { Box, Stack } from '@mui/material';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { images } from '../../constants';
import { Link } from 'react-router-dom';


const OurStory = () => {
  return (
    <section className='container our-story-section'>
        <Stack className='our-story-box' maxWidth='100%' sx={{ justifyContent: 'start', alignItems: 'center', flexWrap: 'wrap', gap: { md: 5 } }} direction={{ md: 'row' }} >

            <div className='backgroung-img1' data-aos="zoom-in-up" data-aos-delay="400">
                <LazyLoadImage 
                    effect="blur"
                    className="img" 
                    src={images.bg12} 
                    alt='backgroung img' 
                />
            </div>

            <Stack sx={{ justifyContent: 'center', alignItems: 'center', gap: 4 }} direction={{ md: 'row' }}>
                <div className='our-story-img'>
                    <LazyLoadImage 
                        effect="blur"
                        className="img" 
                        src={images.OurStory1} 
                        alt='our story' 
                    />
                </div>
                <div className='our-story-img'>
                    <LazyLoadImage 
                        effect="blur"
                        className="img" 
                        src={images.OurStory2} 
                        alt='our story' 
                    />
                </div>
            </Stack>

            <div className='backgroung-img2' data-aos="zoom-in-down">
                <LazyLoadImage 
                    effect="blur"
                    className="img" 
                    src={images.bg11} 
                    alt='backgroung img' 
                />
            </div>
            
            <Stack className='our-story-content' sx={{ justifyContent: 'center', alignItems: 'center' }} data-aos="fade-left" data-aos-delay="400">
                <Stack sx={{ justifyContent: 'center', alignItems: 'center', px: { sm: '60px', md: '60px' }, py: { sm: '60px' } }}>
                    <div className='section-heading'>
                        <h2 className='subtitle'>
                            Discover
                        </h2>
                        <h1 className='title'>
                            Our Story
                        </h1>
                    </div>

                    <p className='our-story-text'>
                        Experience our flavorful journey: From humble origins to wing destination, we share our commitment to taste and excellence.
                    </p>

                    <Link to='/about-us' style={{textDecoration: 'none'}}>
                        <div className='our-story-btn'>
                            <p>View More</p>
                        </div>
                    </Link>
                </Stack>
            </Stack>
        </Stack>
    </section>
  )
}

export default OurStory