import { Box, Grid } from '@mui/material';
import React from 'react';
import './GoogleMap.css';

const GoogleMap = () => {
  return (
    <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }} >
        <div className='google-map' data-aos="zoom-in-up">
            <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d180.13919821740345!2d-79.28351993718785!3d43.74737913471379!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d1fc6f49a821%3A0x947d0f91e2caabe3!2sRoadhouse%20Wings!5e0!3m2!1sen!2slk!4v1684999586124!5m2!1sen!2slk" 
                width="100%" 
                height="100%" 
                style={{ border: 'none', borderRadius: '10px' }}
                allowfullscreen="" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade"
            />
        </div>
    </Grid>
  )
}

export default GoogleMap