import api from "./httpConfig";

const LiquorAPI = {

 getAllLiquor : async () =>{
    try
    {
    const response = await api.get("/liquor/getAllLiquor");
    return response;
    } 
    catch(err)
    {
      console.log(err);
    }
  },

  postLiquor : async (formData) =>{
    try
    {   
    const response = await api.post("/liquor/addLiquor",formData, {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("jwtToken"),
      }
    });
    return response;
    } 
    catch(err)
    {
      console.log(err);
    }
  },

  updateLiquor : async (id,formData) =>{
    try
    {   
    const response =  await api.put(`/liquor/updateLiquor/${id}`,formData, {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("jwtToken"),
      }
    });
    return response;
    } 
    catch(err)
    {
      console.log(err);
    }
  },

  deleteLiquor : async (id) =>{
    try
    {   
    const response =  await api.delete(`/liquor/deleteLiquor/${id}`, {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("jwtToken"),
      }
    });
    return response;
    } 
    catch(err)
    {
      console.log(err);
    }
  }
};


export default LiquorAPI;
