import React, { useState, useEffect } from "react";
import LiquorSliderCard from "./LiquorSliderCard";
import "./LiquorSlider.css";
import { images } from "../../constants";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Skeleton from "@mui/material/Skeleton";
import liquorAPI from "../../api/liquor";

const LiquorSlider = () => {
  const [selectedCategory, setSelectedCategory] = useState("Cocktail");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [liquorData, setLiquorData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);


  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const getData = async () => {
    try {
      console.log("Calling API to fetch liquor data...");
      const allLiquorData = await liquorAPI.getAllLiquor();
      console.log("API response:", allLiquorData.data);
      setLiquorData(allLiquorData.data);

      // Extract unique categories from the data
      const uniqueCategories = [...new Set(allLiquorData.data.map((liquor) => liquor.category))];
      setCategories(uniqueCategories);
      setIsLoading(false);
      setIsLoadingCategories(false);
    } catch (error) {
      console.error("Error fetching liquor data:", error);
      setIsLoading(true);
      setIsLoadingCategories(true);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const filteredLiquors =
    selectedCategory === "" ? liquorData : liquorData.filter((liquor) => liquor.category === selectedCategory);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? filteredLiquors.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === filteredLiquors.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    liquorData.length === 0 ? null :
    <div className="liquor-slider">
      {/* Display the Skeleton if data is loading */}
      {isLoading ? (
        <div className="liquor-card-container">
          <Skeleton variant="rectangular" width={450} height={550} animation="wave" sx={{borderRadius: '10px'}} />
        </div>
      ) : (
        <div className="liquor-card-container">
          <LiquorSliderCard data={filteredLiquors[currentIndex]} />
          <div className="background-img" data-aos="zoom-in-down">
            <img src={images.bg8} alt="background img" />
          </div>
        </div>
      )}

      <div className="arrow">
        <div className="previous-arrow" onClick={handlePrevious}>
          <ArrowBackRoundedIcon className="prev-icon" sx={{ stroke: "#ffffff", strokeWidth: 1 }} />
        </div>
        <div className="next-arrow" onClick={handleNext}>
          <ArrowBackRoundedIcon className="next-icon" sx={{ stroke: "#ffffff", strokeWidth: 1 }} />
        </div>
      </div>
      {isLoadingCategories ? (
        <div className="category-container">
          <Skeleton variant="text" style={{ width: 350, height: 60 }} animation="wave" />
          <div className="line-skeleton" animation="wave" />
          <Skeleton variant="text" style={{ width: 350, height: 60 }} animation="wave" />
          <div className="line-skeleton" animation="wave" />
          <Skeleton variant="text" style={{ width: 350, height: 60 }} animation="wave" />
        </div>
      ) : (
        <div className="category-container" data-aos="fade-left">
          {categories.map((category, index) => (
            <div
              key={category}
              className={`category ${selectedCategory === category ? "active" : ""}`}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
              {index !== categories.length - 1 && <div className="line"/>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LiquorSlider;
