import React from "react";
import './Hero.css';
import { Stack, Box } from "@mui/material";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { images } from '../../constants';


const Hero = () => {
  return (
    <section className="container hero-section">
        <Stack className="hero-flex-box" maxWidth='100%' sx={{ justifyContent: 'start', alignItems: { md: 'center' }, flexWrap: 'wrap', display: 'flex' }} direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}>
            
            <div className='backgroung-img1' data-aos="fade-down-right" data-aos-delay="200">
                <img src={images.bgRound} alt='' />
            </div>

            <Box className="hero-content" width={{ xs: '100%', sm: '100%', md: '50%'}}>
                <div className="hero-text" data-aos="fade-up" data-aos-delay="200">
                    <h1 className="hero-title">We provide the best food for you</h1>
                    <p className="hero-dsc">
                        Embark on a journey of taste where every bite is a crafted masterpiece, redefining your wing experience.
                    </p>
                    <Stack className="hero-cta-box" sx={{ justifyContent: 'start', alignItems: 'center', flexWrap: 'wrap', gap: 2 }} direction='row' >
                        <a href="/menu">
                            <div className="hero-cta-btn cta-menu">
                                Our Menu
                            </div>
                        </a>
                        <a href="tel:+16474580939">
                            <div className="hero-cta-btn cta-cantact">
                                Call Us Now
                            </div>
                        </a>
                    </Stack>
                    <Stack className="hero-socialMedia" sx={{ justifyContent: 'start', alignItems: 'center', flexWrap: 'wrap', gap: 2 }} direction='row'>
                        <a><img src={images.facebook} alt="Facebook" data-aos="zoom-in" data-aos-delay="150" /></a>
                        <a><img src={images.instagram} alt="Instagram" data-aos="zoom-in" data-aos-delay="150" /></a>
                        <a><img src={images.twitter} alt="Twitter" data-aos="zoom-in" data-aos-delay="150" /></a>
                        <div className="hero-line" data-aos="fade-right" data-aos-delay="75" />
                    </Stack>
                </div>
            </Box>

            <Box className="hero-img" width={{ xs: '100%', sm: '100%', md: '50%'}}>
                <div className="hero-dishImg">
                    <LazyLoadImage 
                        effect="blur"
                        className="img"
                        src={images.dish2} 
                        alt='dish img' 
                    />
                </div>
                <div className="hero-mainImg">
                    <LazyLoadImage 
                        effect="blur"
                        className="img" 
                        src={images.heroImg} 
                        alt='hero img' 
                    />
                </div>
            </Box>

            <div className='backgroung-img2' data-aos="zoom-in">
                <LazyLoadImage 
                    effect="blur"
                    className="img"
                    src={images.bg2} 
                    alt='backgroung img' 
                />
            </div>

            <div className='backgroung-img3' data-aos="zoom-in">
                <LazyLoadImage 
                    effect="blur"
                    className="img"
                    src={images.bg1} 
                    alt='backgroung img' 
                />
            </div>

            <div className='backgroung-img4' data-aos="zoom-in">
                <LazyLoadImage 
                    effect="blur"
                    className="img"
                    src={images.bg3} 
                    alt='backgroung img' 
                />
            </div>
            
        </Stack>
    </section>
  )
}

export default Hero